export default [
  {
    id: 'ROLE_ADMIN',
    name: 'Administrator',
    short: 'ADM',
    description: 'Hat auf alle Informationen im Klassenbuchmodul Zugriff und kann diese bearbeiten. Kann Änderungen im Stundenplan (Vertretungen, Unterrichtsausfälle) eintragen und diese wieder stornieren.',
    color: 'error',
  },
  {
    id: 'ROLE_TEACHER',
    name: 'Fachlehrer',
    short: 'FL',
    description: 'Kann Unterrichtsdaten und Abwesenheiten nur für eigene Unterrichtsstunden eintragen und bearbeiten.',
    color: 'success',
  },
  {
    id: 'ROLE_CLASSTEACHER',
    name: 'Klassenlehrer',
    short: 'KL',
    description: 'Hat Leserechte für alle Informationen im Klassenbuchmodul. Kann für Schülerinnen und Schüler der eigenen Klasse alle Abwesenheiten bearbeiten. Kann wochenbezogene Bemerkungen eintragen.',
    color: 'success',
  },
  {
    id: 'ROLE_EDIT_LESSON_DATA_AS_ADMIN',
    name: 'Unterrichtsdaten',
    short: 'UDAT',
    description: 'Ermöglicht die Bearbeitung aller Unterrichtsdaten.',
    color: 'warning',
  },
  {
    id: 'ROLE_EDIT_LESSON_REPLECEMENT_AS_ADMIN',
    name: 'Vertretung',
    short: 'VTRET',
    description: 'Ermöglicht das Eintragen und Stornieren von Vertretungen.',
    color: 'warning',
  },
  {
    id: 'ROLE_EDIT_STUDENT_ABSENCE_AS_ADMIN',
    name: 'Abwesenheiten',
    short: 'ABWES',
    description: 'Ermöglicht die Bearbeitung der Abwesenheiten aller Schülerinnen und Schüler.',
    color: 'warning',
  },
]

<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    right
    clipped
    touchless
    disable-route-watcher
    disable-resize-watcher
    class="d-print-none"
    :width="$vuetify.breakpoint.xsOnly ? '100%' : '360'"
  >
    <template v-slot:prepend>
      <v-toolbar
        dense
        flat
        class="px-1"
      >
        <v-toolbar-title class="subtitle-2">
          Benutzer:
          <strong>{{ activeUser?.displayName || activeUser?.email }}</strong>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="drawer = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider />
    </template>

    <v-simple-table
      v-if="activeUser"
      class="text-caption pa-3"
    >
      <tr>
        <th>
          Status:
        </th>
        <td>
          <StatusChip :is-active="activeUser.isActive"/>
          <v-chip
            v-if="isCurrentUser(activeUser)"
            small
            outlined
            class="ml-1 px-2 font-weight-bold"
            color="primary"
          >
            <v-avatar left>
              <v-icon small>mdi-lock-open-variant</v-icon>
            </v-avatar>
            Diese Sitzung
          </v-chip>
        </td>
      </tr>

      <tr>
        <th>
          Erstellt:
        </th>
        <td>
          {{ dateTime(activeUser.createdAt) }}
        </td>
      </tr>

      <tr>
        <th>
          Letzte Anmeldung:
        </th>
        <td>
          {{ dateTime(activeUser.lastLogin) || 'niemals' }}
        </td>
      </tr>

      <tr>
        <th>
          Name:
        </th>
        <td>
          {{ activeUser.displayName }}
        </td>
      </tr>

      <tr>
        <th>
          Email:
        </th>
        <td>
          {{ activeUser.email }}
        </td>
      </tr>

      <tr>
        <th>
          Rollen:
        </th>
        <td>
          <div
            v-for="role in shownRoles"
            :key="role.id"
          >
            <v-chip
              small
              outlined
              :color="role.color"
              class="text-caption font-weight-bold"
            >
              {{ role.name }}
            </v-chip>
            <small>
              {{ role.description }}
            </small>
          </div>
        </td>
      </tr>

      <tr>
        <th>
          Lehrkraft:
        </th>
        <td>
          {{ teacher ? `${teacher.displayName} (${teacher.code})` : '' }}
        </td>
      </tr>
    </v-simple-table>
  </v-navigation-drawer>
</template>


<script>
import { mapGetters, mapState } from 'vuex'
import StatusChip from './status-chip'
import rolesItems from './roles'

export default {
  name: 'DrawerDetails',
  components: {
    StatusChip,
  },
  computed: {
    ...mapState('manage/users', [
      'activeUser',
    ]),
    ...mapGetters('manage/users', [
      'isCurrentUser',
    ]),
    ...mapGetters('common', [
      'teacherByIri',
    ]),
    drawer: {
      get() {
        return !!this.activeUser
      },
      set(value) {
        if (!value) {
          this.$store.dispatch('manage/users/setActiveUser', null)
        }
      },
    },
    dateTime() {
      return (date) => date?.toLocaleDateString('de', {hour: 'numeric', minute: 'numeric'})
    },
    shownRoles() {
      return this.activeUser?.roles
        .filter((roleId) => roleId !== 'ROLE_USER')
        .map((roleId) => rolesItems.find((role) => roleId === role.id) || {id: roleId, name: '?'})
    },
    teacher() {
      return this.teacherByIri(this.activeUser.teacher)
    },
  },
}
</script>


<style lang="scss" scoped>
th {
  width: 1em;
  vertical-align: top;
  white-space: nowrap;
  text-align: left;
}

th, td {
  padding: 4px;
}
</style>

<template>
  <div class="d-inline-block">
    <span
      v-for="(role, index) in shownRoles"
      :key="role.id"
      class="grey--text"
    >
      <v-chip
        v-if="index < 3"
        :title="`${role.name}: ${role.description}`"
        :color="role.color"
        small
        outlined
        class="font-weight-bold"
      >{{ role.short }}</v-chip>
      {{ index === 3 ? `+${shownRoles.length - index}` : '' }}
    </span>
  </div>
</template>


<script>
import roles from './roles'

export default {
  name: 'RolesChips',
  props: {
    roles: {
      type: Array,
      required: true,
    }
  },
  computed: {
    shownRoles() {
      return this.roles
        .filter((roleId) => roleId !== 'ROLE_USER')
        .map((roleId) => roles.find((role) => roleId === role.id) || {id: roleId, name: '?'})
    },
  },
}
</script>


<style lang="scss" scoped>
.v-chip {
  margin: 1px 0;
}
</style>

<template>
  <v-chip
    small
    outlined
    class="px-2 font-weight-bold"
    :color="color"
  >
    <v-avatar left>
      <v-icon small>{{ icon }}</v-icon>
    </v-avatar>
    {{ text }}
  </v-chip>
</template>


<script>
export default {
  name: 'StatusChip',
  props: {
    isActive: Boolean,
  },
  computed: {
    color() {
      return this.isActive ? 'success' : 'error'
    },
    icon() {
      return this.isActive ? 'mdi-checkbox-marked-circle' : 'mdi-close-octagon'
    },
    text() {
      return this.isActive ? 'Aktiv' : 'Gesperrt'
    },
  },
}
</script>

<template>
  <v-dialog
    v-model="dialog"
    max-width="420"
  >
    <v-card>
      <v-card-title class="subtitle-1 text-sm-h6 flex-nowrap">
        <v-icon class="mr-4">mdi-email-outline</v-icon>
        <div class="text-truncate">
          <span>Enladungsemail rausschicken</span>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider/>

      <v-card-text class="py-6 text-center">
        <div>
          An die folgenden Benutzer wird eine Einladungsemail gesendet:
          <ul>
            <li
              v-for="selectedUser in selectedUsers"
              :key="selectedUser['@id']"
            >
              <strong>{{ selectedUser.displayName }}</strong>
              (<strong>{{ selectedUser.email }}</strong>)
            </li>
          </ul>
        </div>
      </v-card-text>

      <v-card-title
        v-if="error"
        class="justify-center"
      >
        <v-alert
          dense
          outlined
          type="error"
        >
          {{ error }}
        </v-alert>
      </v-card-title>

      <v-divider/>

      <v-card-actions class="px-6">
        <v-spacer />
        <v-btn
          text
          @click="dialog = false"
        >
          Abbrechen
        </v-btn>
        <v-btn
          color="primary"
          :loading="savingUser"
          @click="submit()"
        >
          Schicken
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapState } from 'vuex'

export default {
  name: 'DialogInvite',
  data: () => ({
    error: null,
  }),
  computed: {
    ...mapState('manage/users', [
      'selectedUsers',
      'savingUser',
    ]),
    dialog: {
      get() {
        return this.$store.state.manage.users.dialogInvite
      },
      set(value) {
        if (!value) {
          this.$store.dispatch('manage/users/closeDialog')
        }
      },
    },
  },
  watch: {
    dialog() {
      this.error = null
    },
  },
  methods: {
    async submit() {
      this.error = null
      const { dispatch } = this.$store
      try {
        await Promise.all(
          this.selectedUsers.map((user) =>
            dispatch('manage/users/patchUser', {user, patch: {sendWelcomeEmail: true}})
          )
        )
      } catch (e) {
        this.error = e
      }
      if (!this.error) {
        this.dialog = false
      }
    },
  },
}
</script>

<template>
  <div class="d-flex flex-column fill-height">

    <AppBar />

    <AppLoader v-if="loadingUsers" />

    <v-card
      v-else
      class="flex-grow-1 ma-1 px-2 py-1 overflow-auto print-no-shadow print-no-border"
    >
      <TableUsers />
    </v-card>

    <DrawerDetails />
    <DialogEdit />
    <DialogEditRoles />
    <DialogImport />
    <DialogInvite />
    <DialogDelete />

  </div>
</template>


<script>
import { mapState } from 'vuex'
import AppBar from '@/components/app-bar'
import AppLoader from '@/components/app-loader'
import DialogDelete from '@/pages/manage/users.page/dialog-delete'
import DialogEdit from '@/pages/manage/users.page/dialog-edit'
import DialogEditRoles from '@/pages/manage/users.page/dialog-edit-roles'
import DialogImport from '@/pages/manage/users.page/dialog-import'
import DialogInvite from '@/pages/manage/users.page/dialog-invite'
import DrawerDetails from '@/pages/manage/users.page/drawer-details'
import TableUsers from '@/pages/manage/users.page/table-users'

export default {
  name: 'ManageUsersPage',
  components: {
    AppBar,
    AppLoader,
    DialogDelete,
    DialogEdit,
    DialogEditRoles,
    DialogImport,
    DialogInvite,
    DrawerDetails,
    TableUsers,
  },
  computed: {
    ...mapState('manage/users', [
      'loadingUsers'
    ]),
  },
  created() {
    const { dispatch } = this.$store
    dispatch('manage/users/fetchUsers')
    dispatch('common/fetchCollectionOnce', 'teachers')
  },
}
</script>

<template>
  <div class="d-flex align-center pa-3">
    <template v-if="selectedUsers.length === 0">
      <v-btn
        icon
        class="me-1"
        color="primary"
        title="Benutzer hinzufügen"
        @click="$store.dispatch('manage/users/openDialog')"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <v-btn
        icon
        class="me-1"
        color="primary"
        title="Lehrkräfte importieren"
        :disabled="teachersWithoutAccount.length === 0"
        @click="$store.dispatch('manage/users/openDialog', {dialog: 'import'})"
      >
        <v-icon>mdi-account-multiple-plus-outline</v-icon>
      </v-btn>
    </template>

    <template v-if="selectedUsers.length > 0">
      <span
        v-if="selectedUsers.length > 1"
        class="text-subtitle-2 grey--text mx-1"
      >
        {{ selectedUsers.length }} Benutzer:
      </span>

      <v-btn
        v-if="selectedUsers.length === 1"
        icon
        class="me-1"
        color="primary"
        title="Bearbeiten"
        @click="$store.dispatch('manage/users/openDialog', {user: selectedUsers[0]})"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>

      <v-btn
        v-if="selectedUsers.length > 1"
        icon
        class="me-1"
        color="primary"
        title="Benutzerrollen bearbeiten"
        :disabled="selectedUsers.some((u) => isCurrentUser(u))"
        @click="$store.dispatch('manage/users/openDialog', {dialog: 'edit-roles'})"
      >
        <v-icon>mdi-account-edit-outline</v-icon>
      </v-btn>

      <v-btn
        icon
        class="me-1"
        color="primary"
        title="Enladungsemail rausschicken"
        :disabled="selectedUsers.some((u) => !u.isActive)"
        @click="$store.dispatch('manage/users/openDialog', {dialog: 'invite'})"

      >
        <v-icon>mdi-email-outline</v-icon>
      </v-btn>

      <v-btn
        v-if="selectedUsers.every((u) => !u.isActive)"
        icon
        class="me-1"
        color="success"
        title="Entsperren"
        @click="patchSelectedUsers({isActive: true})"
      >
        <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
      </v-btn>

      <v-btn
        v-else
        icon
        class="me-1"
        color="error"
        title="Sperren"
        :disabled="selectedUsers.some((u) => !u.isActive || isCurrentUser(u))"
        @click="patchSelectedUsers({isActive: false})"
      >
        <v-icon>mdi-close-octagon-outline</v-icon>
      </v-btn>

      <v-btn
        icon
        class="me-1"
        color="error"
        title="Löschen"
        :disabled="selectedUsers.some((u) => u.isActive)"
        @click="$store.dispatch('manage/users/openDialog', {dialog: 'delete'})"
      >
        <v-icon>mdi-delete-outline</v-icon>
      </v-btn>
    </template>
  </div>
</template>


<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'TableUsersToolbar',
  computed: {
    ...mapState('manage/users', [
      'selectedUsers',
    ]),
    ...mapGetters('manage/users', [
      'teachersWithoutAccount',
      'isCurrentUser',
    ]),
  },
  methods: {
    async patchSelectedUsers(patch) {
      const { dispatch } = this.$store
      try {
        await Promise.all(
          this.selectedUsers.map((user) =>
            dispatch('manage/users/patchUser', {user, patch})
          )
        )
      } catch (e) {
        console.error(e)
      }
      dispatch('manage/users/fetchUsers', {silent: true})
    },
  },
}
</script>

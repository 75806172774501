<template>
  <v-dialog
    v-model="dialog"
    max-width="580"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :persistent="savingUser"
  >
    <v-card>
      <v-card-title class="subtitle-1 text-sm-h6 flex-nowrap">
        <v-icon class="mr-4">mdi-account-edit-outline</v-icon>
        <div class="text-truncate">
          <span>Benutzerrollen bearbeiten</span>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          icon
          :disabled="savingUser"
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider/>

      <v-card-text class="mt-5">
        <v-row>
          <v-col>
            <RolesSelect
              v-model="roles"
              :disabled="savingUser"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-title
        v-if="error"
        class="justify-center"
      >
        <v-alert
          dense
          outlined
          type="error"
        >
          {{ error }}
        </v-alert>
      </v-card-title>

      <v-divider/>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          :disabled="savingUser"
          @click="dialog = false"
        >
          Abbrechen
        </v-btn>
        <v-btn
          color="primary"
          :loading="savingUser"
          @click="save()"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapState } from 'vuex'
import RolesSelect from './roles-select'

export default {
  name: 'DialogEditRoles',
  components: {
    RolesSelect,
  },
  data() {
    return {
      error: null,
      roles: [],
    }
  },
  computed: {
    ...mapState('manage/users', [
      'savingUser',
      'selectedUsers',
    ]),
    dialog: {
      get() {
        return this.$store.state.manage.users.dialogEditRoles
      },
      set(value) {
        if (!value) {
          this.$store.dispatch('manage/users/closeDialog')
        }
      },
    },
  },
  watch: {
    dialog() {
      Object.assign(this, {
        error: null,
        roles: this.selectedUsers[0]?.roles?.filter((r) => r != 'ROLE_USER') || [],
      })
    },
  },
  methods: {
    async save() {
      this.error = null
      const { dispatch } = this.$store
      const patch = {roles: this.roles}
      try {
        await Promise.all(
          this.selectedUsers.map((user) =>
            dispatch('manage/users/patchUser', {user, patch})
          )
        )
      } catch (e) {
        this.error = e
      }
      dispatch('manage/users/fetchUsers', {silent: true})
      if (!this.error) {
        this.dialog = false
      }
    },
  },
}
</script>

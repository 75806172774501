<template>
  <v-dialog
    v-model="dialog"
    max-width="580"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :persistent="savingUser"
  >
    <v-card>
      <v-card-title class="subtitle-1 text-sm-h6 flex-nowrap">
        <v-icon class="mr-4">mdi-account-multiple-plus-outline</v-icon>
        <div class="text-truncate">
          <span>Benutzer hinzufügen</span>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          icon
          :disabled="savingUser"
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider/>

      <v-card-text class="mt-3">

        <v-simple-table
          v-if="rows.length > 0"
          dense
        >
          <thead>
            <tr>
              <th class="th-check">
                <v-simple-checkbox
                  v-model="allChecked"
                  :indeterminate="someChecked"
                  :disabled="savingUser"
                />
              </th>
              <th class="th-code">Krz</th>
              <th>Name</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="row in rows"
              :key="row['@id']"
            >
              <td>
                <v-simple-checkbox
                  :value="row.checked"
                  :disabled="savingUser"
                  @input="toggleChecked($event, row)"
                ></v-simple-checkbox>
              </td>
              <td class="font-weight-bold">
                {{ row.teacher.code }}
              </td>
              <template v-if="row.checked">
                <td class="px-0">
                  <input
                    v-model="row.displayName"
                    type="text"
                    :disabled="savingUser"
                  />
                </td>
                <td class="px-1">
                  <input
                    v-model.trim="row.email"
                    type="text"
                    :class="{invalid: !!row.emailError}"
                    :disabled="savingUser"
                    @input="validateRows()"
                  />
                  <div class="red--text">
                    {{ row.emailError }}
                  </div>
                </td>
              </template>
              <template v-else>
                <td>
                  {{ row.teacher.firstName }} {{ row.teacher.lastName }}
                </td>
                <td>
                  {{ row.teacher.email }}
                </td>
              </template>
            </tr>
          </tbody>
        </v-simple-table>

        <v-alert
          v-else
          text
          type="success"
          class="mt-8"
        >
          Alle Lehrkräfte wurden erfolgreich hinzugefügt!
        </v-alert>

      </v-card-text>

      <v-card-title
        v-if="error"
        class="justify-center"
      >
        <v-alert
          dense
          outlined
          type="error"
        >
          {{ error }}
        </v-alert>
      </v-card-title>

      <v-divider/>

      <v-row
        class="px-4"
        no-gutters
      >
        <v-col
          v-if="rows.length > 0"
          class="px-2 pt-1 pb-4"
        >
          <v-checkbox
            v-model="sendWelcomeEmail"
            label="Enladungsemails rausschicken"
            dense
            hide-details
          ></v-checkbox>
        </v-col>
        <v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              :disabled="savingUser"
              @click="dialog = false"
            >
              Schließen
            </v-btn>
            <v-btn
              v-if="canSave"
              color="primary"
              :loading="savingUser"
              @click="saveChecked()"
            >
              Hinzufügen ({{ checkedRows.length }})
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapGetters, mapState } from 'vuex'
import * as assert from '@/helpers/validators'

export default {
  name: 'DialogImport',
  data: () => ({
    error: null,
    rows: [],
    savedCount: 0,
    sendWelcomeEmail: true,
  }),
  computed: {
    ...mapState('manage/users', [
      'savingUser',
      'users',
    ]),
    ...mapGetters('manage/users', [
      'teachersWithoutAccount',
    ]),
    dialog: {
      get() {
        return this.$store.state.manage.users.dialogImport
      },
      set(value) {
        if (!value) {
          this.$store.dispatch('manage/users/closeDialog')
        }
      },
    },
    allChecked: {
      get() {
        return this.rows.every((row) => row.checked)
      },
      set(checked) {
        this.rows.forEach((row) => this.toggleChecked(checked, row, false))
        this.validateRows()
      },
    },
    someChecked() {
      return !this.allChecked && this.rows.some((row) => row.checked)
    },
    checkedRows() {
      return this.rows.filter((row) => row.checked)
    },
    canSave() {
      return this.checkedRows.length > 0 && this.checkedRows.every((row) => !row.emailError)
    },
  },
  watch: {
    dialog(dialog) {
      if (!dialog && this.savedCount > 0) {
        this.$store.dispatch('manage/users/fetchUsers', {silent: true})
      }
      const rows = dialog ? this.teachersWithoutAccount.map((teacher) => ({
        '@id': teacher['@id'],
        checked: false,
        displayName: '',
        email: '',
        emailError: null,
        teacher,
      })) : []
      Object.assign(this, {
        rows,
        error: null,
        savedCount: 0,
      })
    },
  },
  methods: {
    toggleChecked(checked, row, validate = true) {
      if (checked && !row.checked) {
        row.displayName = `${row.teacher.firstName} ${row.teacher.lastName}`
        row.email = (row.teacher.email || '').trim()
      }
      row.checked = checked
      if (!checked) {
        row.emailError = null
      }
      if (validate) {
        this.validateRows()
      }
    },
    validateRows() {
      this.checkedRows.forEach((row) => {
        row.emailError = this.validateEmail(row.email)
      })
    },
    validateEmail(email) {
      const rules = [
        assert.required,
        assert.email,
        (e) => this.checkedRows.filter((r) => r.email === e).length == 1 || 'Die E-Mail muss eindeutig sein.',
        (e) => !this.users.some((u) => u.email === e) || 'Der Benutzer existiert bereits.',
      ]
      for (const validate of rules) {
        const result = validate(email)
        if (true !== result) {
          return result
        }
      }
      return null
    },
    async saveChecked() {
      this.error = null
      this.validateRows()
      if (!this.canSave) return
      const row = this.checkedRows[0]
      const payload = {
        displayName: row.displayName,
        email: row.email,
        roles: ['ROLE_TEACHER', 'ROLE_CLASSTEACHER'],
        teacher: row.teacher?.['@id'],
        sendWelcomeEmail: this.sendWelcomeEmail,
      }
      try {
        await this.$store.dispatch('manage/users/createUser', payload)
      } catch (e) {
        this.error = e
      }
      if (this.error) return
      this.savedCount += 1
      this.rows = this.rows.filter((r) => r['@id'] !== row['@id'])
      this.saveChecked()
    },
  },
}
</script>


<style lang="scss" scoped>
@import '@/scss/variables.scss';

th, td {
  font-size: 12px !important;
}

.th-check,
.th-code {
  width: 1em;
}

input[type="text"] {
  width: 100%;
  padding: 3px 6px;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;

  &:not(.invalid) {
    border-color: map-get($material-theme, 'dividers');
  }

  &.invalid {
    border-color: var(--v-error-base);
    color: var(--v-error-base);
  }
}
</style>
